import { createTheme } from "@mui/material";

const BASE_FONT_SIZE = 16.0;
const DEFAULT_THEME = 'dark';

/**
 * Common theme configuration
 */
const blinkThemeCommon = createTheme({
  palette: {
    widget: {
      liveTag: {
        background: "linear-gradient(267.01deg, #FF6E49 0%, #E7451D 100%)",
      },
    },
    text: {
      primary: "#ffffff",
      secondary: "#1F2023",
      disabled: "#a5a5a5",
    },
    icons: {
      main: "#ffffff",
      light: "#eeeeee",
      dark: "#AAAAAA",
      contrastText: "rgba(0,0,0,0.87)",
    },
    template: {
      lazyLoadSpinner: {
        background: 'transparent',
        spinnerBorder: '#ADADAD',
        baseBorder: 'white',
      },
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536, //limiting to max 1440 instead of 1536px
    },
  },

  typography: {
    allVariants: {
      fontFamily: ["Formular", "Verdana", "sans-serif"].join(","),
      fontSize: `${16 / BASE_FONT_SIZE}rem`,
      fontSmooth: "antialiased",
      MozOsxFontSmoothing: "antialiased",
      WebkitFontSmoothing: "antialiased",
    },
    h1: {
      fontFamily: "Formular",
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: `${40 / BASE_FONT_SIZE}rem`,
      lineHeight: `${49 / BASE_FONT_SIZE}rem`,
    },
    h1Medium: {
      fontFamily: "Formular Medium",
      fontStyle: "normal",
      fontSize: `${40 / BASE_FONT_SIZE}rem`,
      lineHeight: `${49 / BASE_FONT_SIZE}rem`,
    },
    h2: {
      fontFamily: "Formular Medium",
      fontStyle: "normal",
      fontSize: `${32 / BASE_FONT_SIZE}rem`,
      lineHeight: `${39 / BASE_FONT_SIZE}rem`,
      fontFeatureSettings: "'tnum' on, 'lnum' on",
    },
    h3Bold: {
      fontFamily: "Formular Bold",
      fontStyle: "normal",
      fontSize: `${24 / BASE_FONT_SIZE}rem`,
      lineHeight: `${29 / BASE_FONT_SIZE}rem`,
      fontFeatureSettings: "'tnum' on, 'lnum' on",
    },
    h3Medium: {
      fontFamily: "Formular Medium",
      fontStyle: "normal",
      fontSize: `${24 / BASE_FONT_SIZE}rem`,
      lineHeight: `${29 / BASE_FONT_SIZE}rem`,
      fontFeatureSettings: "'tnum' on, 'lnum' on",
    },
    h4Bold: {
      fontFamily: "Formular Bold",
      fontStyle: "normal",
      fontSize: `${20 / BASE_FONT_SIZE}rem`,
      lineHeight: `${24 / BASE_FONT_SIZE}rem`,
    },
    h4Medium: {
      fontFamily: "Formular Medium",
      fontStyle: "normal",
      fontSize: `${20 / BASE_FONT_SIZE}rem`,
      lineHeight: `${24 / BASE_FONT_SIZE}rem`,
      fontFeatureSettings: "'tnum' on, 'lnum' on",
    },
    body1Bold: {
      fontFamily: "Formular Bold",
      fontStyle: "normal",
      fontSize: `${16 / BASE_FONT_SIZE}rem`,
      lineHeight: `${20 / BASE_FONT_SIZE}rem`,
    },
    body1Medium: {
      fontFamily: "Formular Medium",
      fontStyle: "normal",
      fontSize: `${16 / BASE_FONT_SIZE}rem`,
      lineHeight: `${20 / BASE_FONT_SIZE}rem`,
    },
    body1: {
      fontFamily: "Formular",
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: `${16 / BASE_FONT_SIZE}rem`,
      lineHeight: `${20 / BASE_FONT_SIZE}rem`,
    },
    body2Bold: {
      fontFamily: "Formular Bold",
      fontStyle: "normal",
      fontSize: `${14 / BASE_FONT_SIZE}rem`,
      lineHeight: `${17 / BASE_FONT_SIZE}rem`,
    },
    body2: {
      fontFamily: "Formular",
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: `${14 / BASE_FONT_SIZE}rem`,
      lineHeight: `${17 / BASE_FONT_SIZE}rem`,
    },
    body3: {
      fontFamily: "Formular",
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: `${12 / BASE_FONT_SIZE}rem`,
      lineHeight: `${15 / BASE_FONT_SIZE}rem`,
    },
    body3Bold: {
      fontFamily: "Formular Bold",
      fontStyle: "normal",
      fontSize: `${12 / BASE_FONT_SIZE}rem`,
      lineHeight: `${15 / BASE_FONT_SIZE}rem`,
    },
    body3Medium: {
      fontFamily: "Formular Medium",
      fontStyle: "normal",
      fontSize: `${12 / BASE_FONT_SIZE}rem`,
      lineHeight: `${15 / BASE_FONT_SIZE}rem`,
    },
    body4: {
      fontFamily: "Formular",
      fontStyle: "normal",
      fontSize: `${10 / BASE_FONT_SIZE}rem`,
      lineHeight: `${12 / BASE_FONT_SIZE}rem`,
    },
    body5: {
      fontFamily: "Formular",
      fontStyle: "normal",
      fontSize: `${6 / BASE_FONT_SIZE}rem`,
      lineHeight: `${6 / BASE_FONT_SIZE}rem`,
    },
    link1: {
      fontFamily: "Formular Bold",
      fontStyle: "normal",
      fontSize: `${16 / BASE_FONT_SIZE}rem`,
      lineHeight: `${20 / BASE_FONT_SIZE}rem`,
    },
    link2: {},
    link3: {},
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#1F2023",
          backgroundImage: "none",
          boxShadow: "none",
          borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          "&": {
            padding: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            color: "#fff",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&": {
            paddingRight: "5px",
          },
          "& .MuiSvgIcon-root": {
            color: "#2697F8",
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&": {
            color: "#2697F8",
            paddingRight: "5px",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.dob-input > .MuiOutlinedInput-root": {
            width: "100%",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "&.checkbox-label": {
            marginRight: 0,
          },
          "&.checkbox-label > .MuiFormControlLabel-label": {
            fontFamily: "Formular Bold",
            fontStyle: "normal",
            fontSize: "12px",
            lineHeight: `${15 / BASE_FONT_SIZE}rem`,
          },
          "&.persons-radio-label": {
            height: "30px",
          },
          "&.persons-radio-label > .MuiFormControlLabel-label": {
            fontFamily: "Formular Bold",
            fontStyle: "normal",
            fontSize: "15px",
            lineHeight: `${17 / BASE_FONT_SIZE}rem`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: `linear-gradient(101.95deg, #1D5CFF 5.85%, #00A9FF 95.35%)`,
          borderRadius: `30px`,
          fontFamily: "Formular",
          fontSize: "12px",
          color: "#fff",
          height: "40px",
          textTransform: "none",
          "&:hover": {
            boxShadow: "0px 0px 16px rgba(29, 92, 255, 0.7)",
          },
          "&:disabled": {
            background: "#5A5A5A !important",
            color: "#A3A3A3",
          },
        },
        containedSizeSmall: {
          height: "24px",
        },
        containedSecondary: {
          background: `linear-gradient(267.01deg, #42FFA7 0%, #23A467 100%)`,
          borderRadius: `30px`,
          fontFamily: "Formular",
          fontSize: "12px",
          color: "#fff",
          height: "40px",
          "&:hover": {
            boxShadow: "0px 0px 16px rgba(47, 199, 128, 0.7)",
          },
          "&:disabled": {
            background: "#5A5A5A !important",
            color: "#A3A3A3",
          },
          "&.MuiButton-containedSizeLarge .MuiTypography-root": {
            textTransform: "none",
            fontWeight: "bold",
            fontSize: "14px",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        circular: {
          fontFamily: "Formular Bold",
          fontSize: `${16 / BASE_FONT_SIZE}rem`,
          lineHeight: `${20 / BASE_FONT_SIZE}rem`,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          paddingLeft: "5px",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          maxWidth: `${1440 - 24}px`,
          marginTop: "0px",
          marginLeft: "0px",
          paddingLeft: "0px",
          "& .MuiGrid-item": {
            marginTop: "4px",
            marginLeft: "0px",
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {},
        "&:focus": {},
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        icon: {
          fill: "#fff",
        },
        select: {
          "&:focus": {
            border: "none",
          },
          "&:before": {
            borderColor: "orange",
          },
          "&:after": {
            borderColor: "green",
          },
        }  
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        "root": {
          "&.Mui-focused": {
            "color": "#A3A3A3"
          }
        }
      }
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          "& .MuiYearPicker-root": {
            "& .PrivatePickersYear-root": {
              button: {
                "&:hover": {
                  "&.Mui-disabled": {
                    cursor: 'default'
                  }
                }
              }
            }
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            "& fieldset": {
              "& legend": {
                "& span": {
                  padding: '0 8px 0 5px',
                }
              }
            },
          }
        }
      }
    }
  },
});

/**
 * Dark mode
 */
const blinkThemeDark = createTheme(blinkThemeCommon, {
  palette: {
    mode: "dark",
    background: {
      default: "#1F2023",
      paper: "#27292B",
      base: "#1F2023",
    },
    text: {
      primary: "#ffffff",
      // secondary: "#1F2023",
      secondary: "#A3A3A3",
      disabled: "#A3A3A3",
      error: "#FF0000",
    },
    divider: "#303134",
    widget: {
      header: {
        background: "#27292B",
        color: "#ffffff",
        border: "#313436",
      },
      footer: {
        background: "#27292B",
        color: "#ffffff",
        border: "#313436",
      },
      body: {
        background: "#27292B",
        color: "#ffffff",
        border: "#313436",
      },
    },
    widgetHeader: "#1F2023",
    subHeader: "#A3A3A3",
    lazyLoadSpinner: {
      background: '#303134',
      spinnerBorder: 'rgba(255, 255, 255, .3)',
      baseBorder: '#FFFFFF',
    },
    popOver: {
      hover: {
        background: "rgba(249, 249, 249, 0.2)"
      }
    },
    profileContainer: {
      background: "#fff",
      text: "#000",
    },
    heroContainer: {
      text: "#fff",
    },
    template: {
      container: {
        background: "#181818",
        color: "white",
        border: "#1585FE",
      },
      options: {
        background: {
          default: "#181818",
          active: "white",
          disabled: "#363636",
        },
        text: {
          default: "white",
          disabled: "#A3A3A3",
          active: "black",
        },
        active: {
          background: "white",
          color: "black"
        }
      }
    },
    accounts: {
      divider: "#606364",
      linkColor: "#4ba0f4",
      containerList: {
        background: "#303136"
      },
      accountList: {
        background: "#242527"
      }
    },
    scroll: {
      base: "transparent",
      hover: {
        base: "rgba(0, 0, 0, 0.5)",
        thumb: "#555",
        track: "#1C1E1F",
        thumbShadow: "inset 0 0 6px rgba(0,0,0,.3)"
      }
    },
    message: {
      body: {
        background: "#26292B",
      },
      header: {
        background: "#1C1E1F",
        color: "#FFFFFF",
      },
      search: {
        background: "#27292b",
        color: "#FFFFFF",
      },
      thread: {
        lightBackground: "#181818",
        darkBackground: "#090909",
        hoverBackground: "#232324",
        dividerColor: "#303134",
        primaryText: "#FFFFFF",
        secondaryText: "#808080",
        spinner: "#E0E0E0",
      },
    },
    history: {
      statements: {
        green: {
          background: '#242f24',
          color: '#9ae89a',
        },
        red: {
          background: '#352727',
          color: '#ff4545',
        }
      }
    }
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        circular: {
          backgroundColor: "gray",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: '#27292B',
          "& .MuiTableCell-head": {
            background: '#27292B',
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          background: '#303134',
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiIconButton-root": {
            "& .MuiSvgIcon-root": {
              color: 'white',
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiTextField-root": {
            background: "#1F2023",
            "& fieldset": {
              borderColor: '#8d8d8d',
            }
          },
          "& .MuiFormControl-root": {
            label: {
              color: '#8d8d8d',
            }
          },
          "& .MuiInputBase-root": {
            input: {
              color: '',
            }
          },
          "&.Mui-focused": {
            "& fieldset": {
              borderColor: '',
            }
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            // background: "#26292B",
            "& fieldset": {
              borderColor: '#8d8d8d',
            },
            "& .MuiSvgIcon-root": {
              fill: '#FFFF',
            }
          }
        }
      }
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          "& .MuiButtonBase-root": {
            "&.Mui-disabled": {
              "& .MuiSvgIcon-root": {
                fill: '#A3A3A3',
              }
            }
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#1F2023",
          color: "#FFFFFF",
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            fill: '#FFFFFF'
          }
        }
      }
    }
  },
});

/** Light mode */
const blinkThemeLight = createTheme(blinkThemeDark, {
  palette: {
    mode: "light",
    background: {
      default: "#FFFFFF",
      paper: "#E5E5E5",
      base: "#E5E5E5",
    },
    text: {
      primary: "#000",
      secondary: "#4E5276",
      disabled: "#4E5276",
    },
    widget: {
      header: {
        background: "#E5E5E5",
        color: "#000",
        border: "#c6c5c5",
      },
      footer: {
        background: "#E5E5E5",
        color: "#000",
        border: "#c6c5c5",
      },
      body: {
        background: "#E5E5E5",
        color: "#000",
        border: "#c6c5c5",
      },
    },
    widgetHeader: "#F5F5F5",
    subHeader: "#F3F3F1",
    lazyLoadSpinner: {
      background: '#F5F5F5',
      spinnerBorder: '#474747',
      baseBorder: '#ADADAD',
    },
    popOver: {
      hover: {
        background: "rgba(255, 255, 255, 0.7)"
      }
    },
    profileContainer: {
      background: "#333333",
      text: "#fff",
    },
    heroContainer: {
      text: "#fff",
    },
    template: {
      container: {
        background: "#c6c5c5",
        color: "white",
        border: "#1585FE",
      },
      options: {
        background: {
          default: "#c6c5c5",
          active: "white",
          disabled: "#D7D6D6",
        },
        text: {
          default: "black",
          disabled: "#4E5276",
          active: "black",
        },
        active: {
          background: "white",
          color: "black"
        }
      }
    },
    accounts: {
      divider: "#A3A3A3",
      linkColor: "#4ba0f4",
      containerList: {
        background: "#F5F5F5"
      },
      accountList: {
        background: "#E5E5E5"
      }
    },
    scroll: {
      base: "transparent",
      hover: {
        base: "#a3a3a338",
        thumb: "#7d7d7d",
        track: "#a3a3a338",
        thumbShadow: "inset 0 0 6px rgba(0,0,0,.3)"
      }
    },
    message: {
      body: {
        background: "#E5E5E5",
      },
      header: {
        background: "#A5A5A5",
        color: "#000000",
      },
      search: {
        background: "#FFFFFF",
        color: "#000000",
      },
      thread: {
        lightBackground: "#c6c5c5",
        darkBackground: "#E2E2E2",
        hoverBackground: "#F3F3F1",
        dividerColor: "#FFFFFF",
        primaryText: "#000000",
        secondaryText: "#474747",
        spinner: "#FFFFFF",
      },
    },
    history: {
      statements: {
        green: {
          background: '#71d271',
          color: 'black',
        },
        red: {
          background: '#ff4f4f',
          color: 'black',
        }
      }
    }
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        circular: {
          backgroundColor: "darkgray",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: '#A5A5A5',
          "& .MuiTableCell-head": {
            background: '#A5A5A5',
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          background: '#F5F5F5',
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiIconButton-root": {
            "& .MuiSvgIcon-root": {
              color: 'black',
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiTextField-root": {
            background: "#ECECEC",
            "& fieldset": {
              borderColor: 'black',
            }
          },
          "& .MuiFormControl-root": {
            label: {
              color: '#0A0A0A',
            }
          },
          "& .MuiInputBase-root": {
            input: {
              color: 'black',
            }
          },
          "& .MuiAutocomplete-popupIndicator, & .MuiAutocomplete-clearIndicator": {
            "& .MuiSvgIcon-root": {
              color: '#0A0A0A',
            },
            "&:hover": {
              backgroundColor: "inherit"
            }
          },
          "&.Mui-focused": {
            "& fieldset": {
              borderColor: 'black',
            }
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            background: "#ECECEC",
            "& fieldset": {
              borderColor: 'black',
              color: 'black',
            },
            "& .MuiSvgIcon-root": {
              fill: '#0A0A0A',
            }
          },
          "& .MuiInputLabel-formControl": {
            "&.Mui-focused": {
              color: '#1976d2',
            }
          }
        }
      }
    },
    MuiDatePicker: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-formControl": {
            color: '#0A0A0A',
          },
          "& .MuiInputBase-root ": {
            "& .MuiInputAdornment-root": {
              "& .MuiButtonBase-root": {
                "& .MuiSvgIcon-root": {
                  fill: '#0A0A0A',
                }
              }
            },
            input: {
              color: 'red',
            },
            label: {
              color: '#0A0A0A',
            }
          },
          
        }
      }
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          "& .MuiButtonBase-root": {
            "& .MuiSvgIcon-root": {
              fill: '#0A0A0A'
            },
            "&.Mui-disabled": {
              "& .MuiSvgIcon-root": {
                fill: '#707070',
              }
            }
          },
          "& .MuiPickersDay-root": {
            background: 'lightgray',
            color: '#333333',
            "&.Mui-disabled": {
              color: '#8A8A8A'
            },
            "&:hover": {
              background: '#F5F5F5',
              border: '1px solid #474747',
              color: '#292929',
            }
          },
          "& .MuiYearPicker-root": {
            "& .PrivatePickersYear-root": {
              button: {
                "&:hover": {
                  background: '#EBEBEB',
                  border: '1px solid #7A7A7A',
                  color: '#333333',
                  "&.Mui-selected": {
                    background: '#1565c0',
                    borderColor: 'transparent',
                    color: '#FFFFFF',
                  },
                  "&.Mui-disabled": {
                    background: 'inherit',
                    borderColor: 'transparent',
                  }
                }
              }
            }
          },
          "& .MuiMonthPicker-root": {
            button: {
              "&:hover": {
                background: '#EBEBEB',
                border: '1px solid #7A7A7A',
                color: '#333333',
                "&.Mui-selected": {
                  background: '#1565c0',
                  borderColor: 'transparent',
                  color: '#FFFFFF',
                },
                "&.Mui-disabled": {
                  background: 'inherit',
                  borderColor: 'transparent',
                }
              }
            }
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#F5F5F5",
          color: "black",
          "&.MuiTablePagination-toolbar": {
            backgroundColor: "#E5E5E5"
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        "root": {
          backgroundColor: "white",
          color: "white",
          borderBottom: "1px solid white",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            fill: 'black'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        "root": {
          "&.Mui-focused": {
            color: '#27292B'
          }
        }
      }
    }
  },
});

export { blinkThemeCommon, blinkThemeDark, blinkThemeLight, DEFAULT_THEME };
