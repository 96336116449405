import React,{useState, useRef} from 'react'; 
import Box from '@mui/material/Box'; 
import Container from '@mui/material/Container';   
import HeroSection from '../components/heroSection'; 
import InfoCard from '../components/InfoCard' 
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast'; 

const REGISTRATION_URI =
  "https://func-landingapi-prod.azurewebsites.net/api/registration/get-early-access";



function Home() { 
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);

  const [, setEmailSubmitted] = useState(false);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const submitEmail = async (email) => {

    let isValidEmail = validateEmail(email);
    if (!isValidEmail) return alert("Invalid email");

    setSubmissionInProgress(true);
    const data = { emailAddress: email };
    axios
      .post(REGISTRATION_URI, JSON.stringify(data), {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((r) => {
    console.log(r);
        setEmailSubmitted(true);
        toast.success('Thanks, you\'re in line for access once we are ready!');
        setSubmissionInProgress(false);
        if(inputRef?.current) {
          inputRef.current.value = '';
        }
        if(inputRef2?.current) {
          inputRef2.current.value = '';
        }
      })
      .catch((e) => {
        // console.log(e);
        toast.error('Oops, Something went wrong! Please try later...');
        setEmailSubmitted(false);
        setSubmissionInProgress(false);
        if(inputRef?.current) {
          inputRef.current.value = '';
        }
        if(inputRef2?.current) {
          inputRef2.current.value = '';
        }
      });
  };



  return ( 
      <Box sx={{ flexGrow: 1, paddingTop: "10px"  }}>
      <Toaster />
        <Container maxWidth="lg"> 
          <HeroSection /> 
          <InfoCard inputRef={inputRef} onSubscribe={submitEmail} submissionInProgress={submissionInProgress} />   
        </Container> 
      </Box> 
  );
}

export default Home;
