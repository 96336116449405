import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/Toolbar";
import Button from "@mui/material/Button"; 
import Logo from "../assets/images/logo.png"; 
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"

const Header = ({inputRef}) => { 

  const location = useLocation(); 
  
  return (
    <AppBar
      className={"header"}
      position="fixed"
      sx={{
        backgroundColor: "widgetHeader",
      }}
    >
      <Toolbar disableGutters className="header_container">
        <Box>
          <Link to={'/'}><img src={Logo} alt="logo"  /></Link>
        </Box>
        <Box className={"header__right-section"}>
          <Box> 
          </Box>
          <Box>
           {location.pathname !== '/privacy-policy' && <Button onClick={() => {
              inputRef?.current?.focus();
            }} variant="contained">{"Get Notified"}</Button>} 
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
