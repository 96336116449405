import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';  

function Privacy() { 

  React.useEffect(() => {
    window.scrollTo(0,0);
  }, [])


  return ( 
      <Box sx={{ flexGrow: 1, paddingTop: "10px" }}>
        <Container maxWidth="lg" sx={{color:"#000"}}>
          <h1 className="privacy_heading">Privacy Policy</h1>
          <section className="important-info">
            <p>IMARKETS ("iMarkets" or "we") is dedicated to protecting the privacy rights of our online users ("users" or "you").</p>
            <p>This Online Privacy Policy ("Privacy Policy") has been crafted to inform you as to the ways we collect, store, use, and manage the information you provide in connection with any IMARKETS or application ("Service").</p>
            <p>Please note that the scope of this Privacy Policy is limited to only information collected from IMARKETS through use of the Service.</p>
            <p>By using the Service, you are agreeing to the collection and use of your personal information as outlined in this Privacy Policy.</p>
            <p>We may amend the Privacy Policy from time to time, and we encourage you to consult the Privacy Policy regularly for changes.</p>
          </section>
          <section className="info-collect">
            <h2>Information Collected and Used</h2>
            <p>IMARKETS's primary objective in collecting user information is to provide and enhance the Service and to enable users to easily navigate and enjoy the Service.</p>
            <p>When you start using any of the IMARKETS applications, we may collect and record the information you provide us voluntarily through the social networking website you use to access the Service ("SN Website") in accordance with the policies and terms of the SN Website.</p>
            <p>The types of personal information collected in accordance with the activities listed above may vary depending on the activity.</p>
            <p>This "personal information" is defined as information about you that can be used to contact or identify you.</p>
            <p>Personal information may include: (1) your name, (2) SN Website user identification number, or (3) email address.</p>
            <p>Additionally, we may acquire information from you through (1) your access and participation in message boards on the Service (2) your email of a question to our customer support or (3) your purchase of a virtual product of any type.</p>
            <p>Please note that because a third party site manages the purchases of virtual goods offered by the Service, we do not have access to your credit card details and billing information.</p>
            <p>IMARKETS may also invite you to share non-personal information about yourself which may include but is not limited to: (1) your age or date of birth; (2) your gender; (3) information about the hardware you may own; (4) your favorite websites, friend information and fan pages (collectively referred to as "non-personal information").</p>
            <p>If non-personal information is collected for an activity that also requires personal information, IMARKETS may combine your non-personal information with your personal information in an attempt to provide you with a better user experience, to improve the quality and value of the Services and to analyze how our Service is used.</p>
            <p>In addition to information collected as described above, you may choose to share additional information about yourself throughout the Service, such as while opening a trading account, requesting customer support or on message boards.</p>
            <p>Any actions you perform with other users are considered public, and are your sole discretion.</p>
            <p>Finally, any time you access the Service, we may collect aggregate information including but not limited to internet protocol addresses, browser type, browser language, referring / exit pages and URLs, platform type, number of clicks, domain names, landing pages, pages viewed and the order of those pages, the amount of time spent on particular pages, and the date and time of this activity.</p>
            <p>We use this information to better tailor the Service to your interests, to provide you more efficient customer support, and to overall enhance the Service.</p>
            <p>In all cases of data access and collection, the information you provide will not be disclosed, rented, leased, loaned, sold, or otherwise voluntarily distributed to unaffiliated third parties and will be used solely for the purpose of providing you with and improving the Service.</p>
          </section>
          <section className="other-disclosure">
            <h2>Other Disclosure of Your Personal Information</h2>
            <p>You understand and agree that we may be required to disclose any personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to avoid liability, to comply with legal process, including but not limited to a search warrant, subpoena, statute, or court order, or to protect our rights and property, or that of the public.</p>
            <p>Note that IMARKETS is not required to question or contest the validity of any search warrant, subpoena or other similar governmental request that IMARKETS may receive.</p>
            <p>Additionally, in the event of a merger, acquisition, reorganization, bankruptcy, or other similar event, customer information may be transferred to IMARKETS's successor or assignee, if permitted by and done in accordance with applicable law.</p>
            <p>The following services receive your personal data:</p>
            <ul><li>Intercom:<ul><li>email</li>
              <li>phone</li>
              <li>user name</li>
            </ul></li>
            </ul><p>For the purpose of CRM, email communications, in-app communications, and Customer Support. The data is used strictly by iMarkets and not shared with anybody else.</p>
            <ul><li>Mixpanel:<ul><li>ip</li>
              <li>email</li>
              <li>user login</li>
              <li>first name</li>
              <li>last name</li>
              <li>user name</li>
            </ul></li>
            </ul><p>For the purpose of analyzing data trends and improving customer experience. The data is used strictly by iMarkets and not shared with anybody else.</p>
            <p>Intercom and Mixpanel are covered by their own GDPR agreement. Those entities are both GDPR compliant.</p>
          </section>
          <section className="cookies">
            <h2>Cookies</h2>
            <p>A cookie is a small data file that we transfer to your computer’s hard disk, generally to quickly identify a user's computer and to "remember" things about the user's visit, such as your preferences or a user name and password.</p>
            <p>The Service sends cookies to your computer when you access or view content of a IMARKETS.</p>
            <p>Information contained in a cookie may be linked to your personal information for purposes such as improving the quality of our service, tailoring recommendations to your interests, and making the Service easier to use.</p>
            <p>You can disable cookies at any time, although you may not be able to access or use features of the Service.</p>
          </section>
          <section className="links">
            <h2>Links</h2>
            <p>The Service may contain links to other websites.</p>
            <p>The fact that the Service links to a website or presents a banner ad or other type of advertisement does not mean that IMARKETS endorses or authorizes that third party, nor is it a representation of our affiliation with that third party.</p>
            <p>IMARKETS is in no way responsible for the privacy policies of these other websites to which you chose to access from the Service.</p>
            <p>Please be sure to read the privacy policies of those websites when exiting the Service.</p>
          </section>
          <section className="change-info">
            <h2>Changing or Deleting Your Information</h2>
            <p>You may review, update, correct or delete any personal information by changing the applicable information by changing the applicable information through the SN Website.</p>
            <p>If you completely delete all this information, your account may become deactivated.</p>
            <p>If you would like us to delete your record in our system, please contact us and we will attempt to accommodate your request if we are not legally obligated to retain the record.</p>
          </section>
          <section className="security">
            <h2>Security</h2>
            <p>We have put in place reasonable technical and organizational measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration or disclosure.</p>
            <p>However, we cannot guarantee that unauthorized third parties will never be able to overcome those measures or use your personal information for improper purposes.</p>
            <p>Also please note that email and messaging systems are not considered secure, so we discourage you from sending us personal information through these mediums.</p>
          </section>
          <section className="children">
            <h2>Policy Regarding Children</h2>
            <p>The Service is not geared toward children under the age of 13 and we do not knowingly collect personal information from children under the age of 13.</p>
            <p>If we learn that a child under 13 has provided us with personal information we will delete such information from our files as quickly as possible.</p>
          </section>
          <section className="contact">
            <h2>Contacting Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@imarkets.com">support@imarkets.com</a>.</p>
          </section>
          <div className="privacy_footer"><p>© 2023 iMarkets Financial, LLC. All rights reserved.</p>
          </div>
        </Container>
      </Box> 
  );
}

export default Privacy;
