import React from "react";
import "./footersection.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography"; 
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

const FooterSection = () => {
  return ( 
      <Box className='footer_section'>
      
        <Grid container className="footer_info" > 
            <hr width="100%" style={{ marginBottom: 30, marginTop: 30, background: "#ccc" }} />
            <Grid  item xs={12} md={12} color="#818181">
The Brokerage, iMarkets, is not currently a FINRA member and will be subject to the FINRA New Member Application process. The Brokerage is wholly-owned subsidiary of TradeOff Financial Corp.
</Grid>
            <Grid item xs={6} md={6} sx={{ textAlign: "left" }}> </Grid>
            <Grid item xs={6} md={6} sx={{ textAlign: "right" }}>
              <Box className="footer_terms-section">
                <Link to={'/privacy-policy'}>
                 {"Terms "}{" | "}{" Privacy"} 
                </Link>
              </Box>
              <Box className="footer_terms-section" sx={{ marginTop: 1 }}>
                <Typography> &copy; 2023 iMarkets, {"All Rights Reserved."}</Typography>
              </Box>
            </Grid> 
        </Grid>
      </Box> 
  );
};

export default FooterSection;
